import React, { Suspense, useMemo } from "react";
import { Card } from "clutch/src/Card/Card.jsx";
import { t } from "i18next";
import Article from "shared/Article.jsx";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import { GAME_FRIENDLY_SHORT_NAME_MAP } from "@/app/constants.mjs";
import AgentAbilitiesPlayer from "@/game-val/AgentAbilitiesPlayer.jsx";
import { GAME_SYMBOL_VAL } from "@/game-val/definition-symbol.mjs";
import type { AgentInlineEntry } from "@/game-val/models/article-inline-entry.mjs";
import { getArticleMetadata } from "@/shared/article-metadata.mjs";
import type { RichTextRenderer } from "@/shared/RenderRichText.js";
import isObject from "@/util/is-object.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function InlineEntryRenderer({
  entryId,
}: {
  entryId: string;
}): JSX.Element | null {
  const {
    val: {
      cms: { entryInline },
    },
  } = useSnapshot(readState);
  const payload = useMemo(() => entryInline[entryId], [entryInline, entryId]);

  if (!isObject(payload)) return null;

  return (
    <Suspense key={entryId} fallback={<Card loading style={{ height: 600 }} />}>
      <AgentAbilitiesPlayer agentKey={(payload as AgentInlineEntry).key} />
    </Suspense>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderer: RichTextRenderer = (node, article, index) => {
  const entryId = node.data?.target?.sys?.id;
  if (node.nodeType === "embedded-entry-inline" && entryId) {
    return <InlineEntryRenderer key={entryId} entryId={entryId} />;
  }
  return null;
};

export default function ValorantArticle() {
  return <Article gameSymbol={GAME_SYMBOL_VAL} renderer={renderer} />;
}

export function meta([id]): Meta {
  const {
    contentful: { entry, canonical },
  } = readState;
  const article = entry[id] ?? entry[canonical[id]];

  if (article) {
    return getArticleMetadata(article);
  }

  const friendlyName = t(...GAME_FRIENDLY_SHORT_NAME_MAP[GAME_SYMBOL_VAL]);
  return {
    title: [
      "contentful:article.meta.title",
      "{{friendlyName}} - {{id}} - Blitz",
      { friendlyName, id },
    ],
    description: [
      "contentful:article.meta.description",
      "Master {{friendlyName}} with expert tips for {{id}}, in-depth guides, and winning strategies from Blitz.gg. Whether you're a beginner or a pro, we've got everything you need to level up your game!",
      { friendlyName, id },
    ],
  };
}
